@import "../../styles/vars";

.action {
  position: relative;
  z-index: 3;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  gap: 17px;
  min-height: 101px;
  padding: 5px 14px 5px 16px;
  border: 1px #702846 solid;
  border-radius: 10px;
  background-image: linear-gradient(to right, #540426, #2B0017);

  &__btn {
    position: relative;
    flex: 0 0 MIN(27.5%, 94px);

    &-body {
      width: 100%;
      display: block;
    }

    &-body-desktop {
      display: none;
    }

    &-inner {
      position: absolute;
      inset: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-title-desktop {
      display: none;
    }

    &-icon {
      position: relative;
      left: 1px;
      display: block;
      width: 41%;
    }
  }

  &__desc {
    position: relative;
    top: 4px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 10px;
    flex: 1 1 auto;
    line-height: .9;
  }

  &__title {
    flex: 1 0 100%;
    margin: 0;
    font-family: Raleway, Arial, sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: .05em;

    @media (min-width: 390px) {
      font-size: 20px;
    }
  }

  &__platforms {
    flex: 1 0 100%;
    margin: .45em 0 .35em;
    font-family: Roboto, Arial, sans-serif;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 1px;

    @media (min-width: 375px) {
      flex: none;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 0;

    > li {
      list-style: none;
    }
  }

  @media (orientation: landscape) {
    gap: 8px;
    min-height: CLAMP(87px, 26.55vh, 101px);

    &__desc {
      position: relative;
      top: 2px;
      line-height: 1;
    }

    &__title {
      font-size: 20px;
    }

    &__platforms {
      margin: 2px 0 3px;
    }

    &__icons {
      gap: 9px;
    }
  }

  @media (orientation: landscape) and (min-width: 812px) {
    gap: 14px;

    &__desc {
      top: 2px;
    }

    &__title {
      margin-bottom: 6px;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
    padding: 0;
    border: none;
    border-radius: 0;
    background-image: none;

    &__btn {
      width: 431px;

      &-inner {
        gap: 15px;
      }

      &-title-desktop {
        display: block;
        font-family: Raleway, Arial, sans-serif;
        font-weight: 800;
        font-size: 23px;
        text-transform: uppercase;
        letter-spacing: .05em;
        color: #fff;
      }

      &-icon {
        width: 49px;
      }
    }

    &__desc {
      top: 0;
      justify-content: center;
      column-gap: 14px;
      width: 324px;
      height: 42px;
      padding: 5px 14px 5px 16px;
      border: 1px #702846 solid;
      border-radius: 10px;
      background-image: linear-gradient(to right, #540426, #2B0017);
    }

    &__title {
      display: none;
    }

    &__platforms {
      font-size: 18px;
      letter-spacing: .05em;
    }
  }
}