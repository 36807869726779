@import "../../styles/vars";

.footer {
  display: none;
  background-color: inherit;

  &__top {
    display: flex;
    flex-direction: column;
    height: CLAMP(29px, 3.85vh, 45px);
    padding-inline: 5px;
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #130000;
    color: #691852;
    min-height: 37px;
  }

  @media (min-width: 1024px) {
    display: block;
  }

  @media (min-width: 1500px) {
    &__top {
      height: 45px;
      margin-bottom: 8px;
    }

    &__bottom {
      min-height: 48px;
    }
  }

  .copyright {
    position: relative;
    top: 4px;
  }
}

.partners {
  display: flex;
  height: inherit;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  flex: 1;

  &__item {
    height: inherit;
    list-style: none;
  }

  &__logo {
    display: block;
    height: inherit;
    width: auto;
    object-fit: contain;
    transform: scale(.7);
  }
}

.copyright {
  font-family: Raleway, Arial, sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: .05em;
}