@keyframes visibility {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.pixelperfect {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 1;

  &_anim {
    animation: 2s visibility linear infinite;
  }

  &-actions {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    display: flex;
    gap: 1px;
    opacity: 0;

    &:hover {
      opacity: 1;
    }

    &__btn {
      background: #fff;
      padding: 3px;
      cursor: pointer;
      color: #000;
      font-size: 10px;
    }
  }

  &__screen {
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    @media (width: 328px) {
      &_p_xs {
        display: block;
      }
    }

    @media (min-width: 374px) and (max-width: 376px) {
      &_p_sm {
        display: block;
      }
    }

    @media (width: 390px) {
      &_p_md {
        display: block;
      }
    }

    @media (width: 710px) and (orientation: landscape) {
      &_l_xs {
        display: block;
      }
    }

    @media (width: 812px) and (orientation: landscape) {
      &_l_sm {
        display: block;
      }
    }

    @media (width: 1280px) and (orientation: landscape) {
      &_imac {
        display: block;
      }
    }

    @media (width: 1920px) and (orientation: landscape) {
      &_desktop {
        display: block;
      }
    }
  }
  

}

.hide {
  display: none !important;
}