@import "../../styles/vars";

.header {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 15px calc(3px + 1vh);

  &__logo {
    width: 270px;
    height: auto;
  }

  @media (min-width: 375px) and (max-width: 389px) {
    padding-bottom: 4px;
    padding-inline: 15px;

    &__logo {
      margin-bottom: 0;
    }
  }

  @media (min-width: 390px) {
    padding-top: 38px;
    padding-bottom: 8px;
    transform: translateX(3.3%);

    &__logo {
      //margin-bottom: 4px;
    }
  }

  &__desc {
    margin: 0;
    padding-inline: 3px;
    font-size: 14px;
    text-align: center;
    letter-spacing: .75px;
  }

  @media (orientation: landscape) {
    flex-direction: row;
    gap: 8px;
    width: CLAMP(435px, 61.27vw, 506px);
    transform: translateX(2%);
    margin: 0 auto;
    padding: 8px 0 3px;

    &__logo {
      width: 86px;
    }

    &__desc {
      text-align: left;
    }
  }

  @media (orientation: landscape) and (min-width: 812px) {
    gap: 27px;
    padding: 11px 0 7px;

    &__desc {
      position: relative;
      top: -2px;
    }
  }

  @media (min-width: 1024px) {
    width: 728px;
    flex-direction: row;
    transform: translateX(0);
    gap: 20px;
    margin: 0 auto;
    padding: 2.8vh 0 2.8vh;

    &__logo {
      width: 163px;
    }

    &__desc {
      top: 3px;
      width: 80%;
      font-size: 20px;
      text-align: left;
      letter-spacing: .5px;
    }
  }

  @media (min-width: 1500px) {
    padding: 44px 0 36px;
    gap: 44px;

    &__desc {
      position: relative;
      top: -1px;
    }
  }
}