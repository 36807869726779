@import "src/styles/vars";

.visual, .visual-back > .visual-back__inner {
  transform: translateX(1%);

  @media (min-width: 375px) {
    transform: translateX(2.3%);
  }

  @media (min-width: 390px) {
    transform: translateX(4%);
  }

  @media (orientation: landscape) {
    transform: translateX(2%);
  }

  @media (orientation: landscape) and (min-width: 812px) {
    transform: translateX(2.4%);
  }

  @media (min-width: 1024px) {
    transform: translateX(0);
  }

  @media (min-width: 1500px) {
    transform: translateX(0);
  }
}

.visual-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;

  &_front {
    z-index: 10;
  }

  &__inner {
    position: relative;
  }

  &__woman {
    position: absolute;
    z-index: 5;
    right: 93.7%;
    bottom: 11%;
    width: 24.2%;

    @media (min-width: 375px) {
      right: 94%;
      bottom: 9%;
      width: 24%;
    }

    @media (min-width: 390px) {
      right: 98%;
      bottom: 9.7%;
      width: 24.9%;
    }
  }

  &__pig {
    position: absolute;
    z-index: 5;
    top: -9.5%;
    left: 45%;
    width: 100%;

    @media (min-width: 375px) {
      top: -7.1%;
      left: 43.3%;
      width: 104%;
    }

    @media (min-width: 390px) {
      top: -6%;
      left: 43.4%;
      width: 103%;
    }
  }

  &__money1 {
    position: absolute;
    z-index: 100;
    left: 92%;
    bottom: 33%;
    width: 29%;
    transform: rotate(63deg);

    @media (min-width: 375px) {
      bottom: 31%;
    }

    @media (min-width: 390px) {
      bottom: 32.9%;
      left: 87.7%;
    }
  }

  &__money2 {
    position: absolute;
    z-index: 10;
    left: 111%;
    bottom: 49%;
    width: 19%;
    transform: rotate(87deg);

    @media (min-width: 390px) {
      left: 106%;
      bottom: 52%;
    }
  }

  &__diamond1 {
    position: absolute;
  }

  &__diamond2 {
    position: absolute;
  }

  &__airship {
    position: absolute;
    z-index: 5;
    top: 2.35%;
    right: 90%;
    width: 35.7%;

    @media (min-width: 375px) {
      top: 5.35%;
    }

    @media (min-width: 390px) {
      top: 3.05%;
      right: 94.1%;
    }
  }

  &__gold {
    position: absolute;
    left: 113%;
    bottom: 79.5%;
    width: 8.5%;
    transform: rotate(-15deg);
  }

  @media (orientation: landscape) {

    &__pig {
      left: 69%;
      top: auto;
      bottom: -7.6%;
      width: auto;
      height: 124%;
    }

    &__woman {
      top: 4%;
      right: 99%;
      bottom: auto;
      width: auto;
      height: 72%;
    }

    &__money1 {
      left: 56%;
      bottom: 3%;
      width: 17%;
      transform: rotate(-3deg);
    }

    &__money2 {
      left: 111.7%;
      bottom: 38.4%;
      width: 11.5%;
      transform: rotate(-25deg);
    }

    &__airship {
      top: 2.75%;
      right: 107%;
      width: 19.7%;
    }
  }

  @media (orientation: landscape) and (min-width: 812px) {
    &__pig {
      left: 69.1%;
      top: auto;
      bottom: -7.7%;
      width: auto;
      height: 124%;
    }

    &__money2 {
      left: 115%;
      bottom: 37%;
      width: 11.2%;
      transform: rotate(-34deg);
    }

    &__airship {
      top: -19.35%;
      right: auto;
      left: 99%;
      width: 17.5%;
    }

    &__gold {
      left: 116.1%;
      bottom: 79.2%;
      width: 8%;
    }
  }

  @media (min-width: 1024px) {
    &__woman {
      top: -3%;
      right: 69%;
      bottom: auto;
      width: auto;
      height: 97%;
    }

    &__pig {
      left: 69.6%;
      top: -23.6%;
      width: auto;
      height: 159.2%;
    }

    &__money1 {
      left: 101%;
      bottom: -3%;
      width: 15.4%;
      transform: rotate(-20deg);
    }

    &__money2 {
      left: 119%;
      bottom: 29.4%;
      width: 11%;
      transform: rotate(42deg);
    }

    &__gold {
      left: 122.3%;
      bottom: 69.8%;
      width: auto;
      height: 18.5%;
      transform: rotate(11deg);
    }

    &__airship {
      top: -18.55%;
      right: auto;
      left: 118.6%;
      width: 16.1%;
    }
  }

  @media (min-width: 1500px) {
    &__woman {
      top: -25%;
      right: 74%;
      width: auto;
      height: 138%;
    }

    &__diamond1 {
      top: 39.6%;
      left: 142.7%;
      width: auto;
      height: 24.4%;
    }

    &__diamond2 {
      top: 53%;
      right: 128.1%;
      width: auto;
      height: 29.5%;
    }

    &__pig {
      left: 81.8%;
      top: 5%;
      width: auto;
      height: 95%;
    }

    &__money1 {
      left: 91.2%;
      bottom: -13%;
      width: 15.4%;
      transform: rotate(-20deg);
    }

    &__money2 {
      left: 118%;
      bottom: 9%;
      width: 13.5%;
      transform: rotate(13deg);
    }

    &__gold {
      left: 134.4%;
      bottom: 65.8%;
      width: auto;
      height: 19.3%;
      transform: rotate(-86deg);
    }
  }
}

.visual {
  position: relative;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: auto;
  max-width: 250px;

  @media (orientation: landscape) and (max-width: 1023px) {
    max-width: 507px;
  }

  @media (min-width: 1024px) {
    max-width: none;
    flex: 0 1 367px;
    justify-content: stretch;
  }

  &__picture-inner {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    @media (min-width: 1024px) {
      display: contents;
    }
  }

  &__device {
    position: relative;
    z-index: 7;
    max-height: 100%;
    max-width: 100%;

    @media (min-width: 1024px) {
      height: 100%;
    }
  }
}

$money_duration: 1.5s;

@keyframes money_rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes money_wave {
  0% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}

.money {
  position: absolute;
  z-index: 20;
  left: 50%;
  transform: translateX(-50%);
  top: 11.5%;
  height: 31.07%;
  aspect-ratio: 1 / 1;


  @media (orientation: landscape) {
    height: 58.7%;
    top: 16.9%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__inner {
    animation: money_rotate $money_duration linear infinite;
  }

  &__figures {
    animation: money_rotate $money_duration linear infinite;
    animation-direction: reverse;
  }

  &__text {
    animation: money_wave $money_duration ease infinite;
    animation-direction: alternate;
  }
}