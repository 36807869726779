//@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;700;800&family=Roboto:wght@400;600;700&display=swap');
@import "vars";
@import "../components/Header/Header";
@import "../components/Footer/Footer";
@import "../components/Action/Action";
@import "src/components/Visual/Visual";
@import "../utils/Pixelperfect/Pixelperfect";

*,*::before,*::after {
  box-sizing: border-box;
}

body {
  font-family: Roboto, Arial, sans-serif;
  color: #fff;
  min-width: $min-width;
  -webkit-font-smoothing: antialiased;
  padding:
          env(safe-area-inset-top)
          env(safe-area-inset-right)
          env(safe-area-inset-bottom)
          env(safe-area-inset-left);
  background-color: #1A000B;
}

.app {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  justify-content: center;
  align-content: center;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-image: url("../assets/bg_mobile_vert.png");
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-color: #1A000B;
  background-size: 119% auto;

  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    background-image: url("../assets/bg_mobile_vert_x2.png");
  }

  @media (min-width: 375px) {
    background-position-y: -5px;
  }

  @media (min-width: 391px) {
    background-size: cover;
  }

  @media (orientation: landscape) {
    background-position: left 50% bottom 0;
    background-image: url("../assets/bg_mobile_horiz.png");

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      background-image: url("../assets/bg_mobile_horiz_x2.png");
    }
  }

  @media (orientation: landscape) and (min-width: 812px) {
    background-image: url("../assets/bg_mobile_horiz2.png");

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)  {
      background-image: url("../assets/bg_mobile_horiz2_x2.png");
    }
  }

  @media (min-width: 1024px) {
    background-image: url("../assets/bg_tablet.png");
    background-position: left 50% top 0;
    background-size: 100% auto;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)  {
      background-image: url("../assets/bg_tablet_x2.png");
    }
  }

  @media (min-width: 1500px) {
    background-image: url("../assets/bg_desktop.png");
    background-size: initial;

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)  {
      background-image: url("../assets/bg_desktop_x2.png");
    }
  }

  @media (min-width: 1920px) {
    background-image: url("../assets/bg_desktop_x2.png");
    background-size: 100% auto;
  }
}

.primary {
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;

  @media (min-width: 1024px) {
    justify-content: flex-start;
  }

  &__inner {
    position: relative;
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;

    @media (min-width: 1024px) {
      justify-content: stretch;
      max-height: 488px;
    }

    @media (min-width: 1500px) {
      max-height: 549px;
    }
  }

  &__actions {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 10;
    padding: 0 11px 8px 12px;

    .action {
      margin-top: 9px;
    }

    @media (min-width: 375px) {
      padding: 0 8px 18px 8px;
    }

    @media (orientation: landscape) {
      padding: 0 20px 8px;
    }

    @media (orientation: portrait) and (max-width: 1023px) {
      &-inner {
        max-width: 497px;
        margin-inline: auto;
      }
    }

    @media (orientation: landscape) {
      &-inner {
        display: flex;
        gap: 13px;
      }
    }

    @media (orientation: landscape) and (max-width: 1023px) {
      .action {
        margin: 0;

        &_play {
          .action__btn {
            order: 2;
          }

          .action__desc {
            order: 1;
            justify-content: right;
            text-align: right;
          }

          .action__title {
            order: 1;
          }

          .action__platforms {
            order: 3;
          }

          .action__icons {
            order: 2;
          }
        }
      }
    }

    @media (min-width: 1024px) {
      gap: 7px;

      &-inner {
        justify-content: center;
        gap: 59px;

        .action {
          flex: 0 0 auto;
        }
      }
    }
  }

  &__actions-money {
    position: absolute;
    z-index: 1;
    width: 10%;
    right: 33%;
    bottom: 69%;
    transform: rotate(4deg);
  }
}